import SendIcon from "@mui/icons-material/Send";
import {
  Alert,
  Box,
  Button,
  FormControlLabel,
  Link as MuiLink,
  TextField,
  Typography,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { Link as GatsbyLink } from "gatsby";
import { FormEventHandler, useState } from "react";
import { useMutation } from "react-query";
import { useColorMode } from "../../00-globals/GlobalWrapper/GlobalWrapper";
import { KontakformularArtikelProps } from "./KontakformularArtikelInterfaces";
import { useKontakformularArtikelStyles } from "./KontakformularArtikelStyles";

const restEndpoint = `https://b2twvpx8.myraidbox.de/wp-json`;

interface ContactFormResponse {
  contact_form_id: number;
  invalid_fields: unknown[];
  message: string;
  posted_data_hash: string;
  status: "validation_failed" | "mail_sent";
}

const KontakformularArtikel = (
  props: KontakformularArtikelProps
): JSX.Element => {
  const { isContrastMode } = useColorMode();
  const { classes, cx } = useKontakformularArtikelStyles({ isContrastMode });

  const [emailText, setEmailText] = useState<string>("");
  const [nameText, setNameText] = useState<string>("");
  const [messageText, setMessageText] = useState<string>("");
  const [dsvgoTrue, setdsvgoTrue] = useState<boolean>(false);
  const [isAlertShowing, setIsAlertShowing] = useState<boolean>(false);

  const closeSuccess = () => setIsAlertShowing(false);

  // wieso data,error,mutate?
  const {
    data,
    error,
    mutate: sendMail,
  } = useMutation<ContactFormResponse>(async () => {
    const baseUrl = restEndpoint;

    const formData = new FormData();

    formData.append("your-name", nameText);
    formData.append("your-email", emailText);
    formData.append("your-message", messageText);
    formData.append("your-subject", "Artikelkommentar");

    formData.append("artikel", props.articleUrl);

    const formUrl = `${baseUrl}/contact-form-7/v1/contact-forms/184/feedback`;

    const rawResponse = await fetch(formUrl, {
      method: "POST",
      body: formData,
    });

    return rawResponse.json() as Promise<ContactFormResponse>;
  });

  const hasError = error || (data && data.status !== "mail_sent");

  const onSubmit: FormEventHandler = (e) => {
    // blockiert page reload
    e.preventDefault();

    sendMail();
    setEmailText("");
    setNameText("");
    setMessageText("");
    setdsvgoTrue(false);
    setIsAlertShowing(true);
  };

  return (
    <Box className={classes.kontaktformStyles}>
      {hasError && <Alert severity="error">Ein Fehler ist aufgetreten</Alert>}

      <form className={classes.form} onSubmit={onSubmit}>
        <TextField
          color="secondary"
          className={classes.short_element}
          margin="normal"
          type="email"
          label="E-Mail-Adresse"
          required={true}
          value={emailText}
          // value uebergabe
          onChange={(e) => setEmailText(e.target.value)}
          onFocus={closeSuccess}
        />

        <TextField
          color="secondary"
          className={classes.short_element}
          margin="normal"
          type="text"
          label="Name"
          required={false}
          value={nameText}
          onChange={(e) => setNameText(e.target.value)}
          onFocus={closeSuccess}
        />

        <TextField
          color="secondary"
          multiline={true}
          rows={4}
          margin="normal"
          fullWidth={true}
          type="text"
          label="Privater Kommentar an die Redaktion"
          required={true}
          value={messageText}
          onChange={(e) => setMessageText(e.target.value)}
          onFocus={closeSuccess}
        />

        <FormControlLabel
          className={classes.formLableStyles}
          checked={dsvgoTrue}
          control={<Checkbox color="secondary" required={true} />}
          label={
            <Typography variant="body1">
              Hiermit willige ich ein, dass meine Daten entsprechend unserer{" "}
              <MuiLink
                className={classes.datenschutzlink}
                underline={"always"}
                component={GatsbyLink}
                to={"/datenschutz"}
              >
                Datenschutzerklärung
              </MuiLink>{" "}
              verarbeitet werden dürfen.
            </Typography>
          }
          onChange={() => {
            // instead of this …
            //
            // if (dsvgoTrue === true) {
            //   setdsvgoTrue(false);
            // } else {
            //   setdsvgoTrue(true);
            // }
            //
            // We can do this:
            // (called functional updates)
            // reactjs.org/docs/hooks-reference.html#functional-updates
            setdsvgoTrue((oldState) => !oldState);
          }}
        />

        <Button
          startIcon={<SendIcon />}
          color="secondary"
          variant="contained"
          className={cx(classes.short_element, classes.kommentarButton)}
          type="submit"
        >
          <Typography variant="h6">
            Kommentar zum Artikel an die Redaktion senden
          </Typography>
        </Button>

        {isAlertShowing && (
          <Alert onClose={closeSuccess} severity="success">
            Nachricht erfolgreich versendet 🥳
          </Alert>
        )}
      </form>
    </Box>
  );
};

export default KontakformularArtikel;
