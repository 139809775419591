import { Container, Grid, Link as MuiLink, Typography } from "@mui/material";
import dayjs from "dayjs";
import { graphql, Link as GatsbyLink } from "gatsby";
import { replaceHtmlWithFunctionComponent } from "../../../helpers/clientSide/replaceTagsWithFunctionComponents/replaceHtmlWithFunctionComponent";
import { mapWpImageInformation } from "../../../helpers/clientSide/WordPress/mapWpImageInformation";
import {
  AuthorPartial,
  pathForAuthor,
} from "../../../helpers/general/links/pathForAuthor";
import { mapWpContent } from "../../../helpers/general/parseWpHtmlToReact/htmlTagsToReactComponents";
import { getMainCategoryForArticle } from "../../../helpers/wordpress/getMainCategoryForArticle";
import { getSubCategoryForArticle } from "../../../helpers/wordpress/getSubCategoryForArticle";
import { getSubCategorySlugForArticle } from "../../../helpers/wordpress/getSubCategorySlugForArticle";
import { useColorMode } from "../../00-globals/GlobalWrapper/GlobalWrapper";
import ArticleContent from "../../01-atoms/ArticleContent/ArticleContent";
import { ChipLink } from "../../01-atoms/ChipLink/ChipLink";
import { SeoBasics } from "../../01-atoms/SeoBasics/SeoBasics";
import WordPressImage from "../../01-atoms/WordPressImage/WordPressImage";
import KontakformularArtikel from "../../02-molecules/KontakformularArtikel/KontakformularArtikel";
import { ArticleFullProps } from "./ArticleFullInterfaces";
import { useArticleFullStyles } from "./ArticleFullStyles";

export const query = graphql`
  query ($id: String!) {
    wpPost(id: { eq: $id }) {
      ...WpPostFields
    }
  }
`;

const ArticleFull = (props: ArticleFullProps): JSX.Element => {
  const { isContrastMode } = useColorMode();
  const { classes, cx } = useArticleFullStyles({ isContrastMode });

  const article = props.data.wpPost;

  const title = article.seo?.title || article.title;
  const descriptionFallback = (article.excerpt || article.title)?.substring(
    0,
    120
  );
  const metaDescription = article.seo?.metaDesc || descriptionFallback;

  // console.log(props.data.wpPost.categories.nodes.find(isSubCategory).name);

  const mappedImage = article.featuredImage // Übersetzer WP zu Gatsby Image
    ? mapWpImageInformation(article.featuredImage?.node)
    : undefined;

  const subcategory = getSubCategoryForArticle(article);
  const subcategorySlug = getSubCategorySlugForArticle(article);

  const maincategory = getMainCategoryForArticle(article);

  const titleImageCaption: string = article.featuredImage?.node.caption;

  // console.log("caption des titlebilds", titleImageCaption);

  return (
    <Container className={classes.artikelContainer}>
      <SeoBasics title={title} description={metaDescription} />

      <Grid
        container={true}
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        mb={2.5}
        mt={2.5}
      >
        <Typography variant="h1" gutterBottom={true} className={classes.center}>
          {article.title}
        </Typography>

        <Typography
          variant="subtitle1"
          gutterBottom={true}
          className={cx(classes.center, classes.smallerWidth)}
        >
          {replaceHtmlWithFunctionComponent(article.excerpt, mapWpContent)}
        </Typography>

        <ChipLink
          label={`${maincategory} / ${subcategory} `}
          href={`/kategorie/${subcategorySlug}`}
          isActive={false}
        />

        <Grid
          // className={classes.autorContainer}
          container={true}
          display="flex"
        >
          <Grid item={true} xs={12}>
            <Typography variant="body1" className={classes.authorWrapper}>
              {"von "}

              <MuiLink
                underline="always"
                component={GatsbyLink}
                className={classes.authorlink}
                to={pathForAuthor(article.author?.node as AuthorPartial)}
              >
                {`${article.author?.node?.firstName} ${article.author?.node?.lastName}`}
              </MuiLink>

              {" am "}

              {dayjs(article.date).format("LL")}
            </Typography>
          </Grid>
        </Grid>

        {/* Artikel Titelbild */}

        <Grid item={true} xs={12} className={classes.imageWrapper}>
          {mappedImage ? (
            <WordPressImage imageInformation={mappedImage} />
          ) : null}
        </Grid>

        {/* Artikel Titelbild Caption */}

        <Typography className={classes.imageWrapper}>
          {titleImageCaption
            ? replaceHtmlWithFunctionComponent(titleImageCaption, mapWpContent)
            : null}
        </Typography>

        <ArticleContent content={props.data.wpPost.content || ""} />

        <KontakformularArtikel articleUrl={article.title} />
      </Grid>
    </Container>
  );
};

export default ArticleFull;
