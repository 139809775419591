import { makeStyles } from "tss-react/mui";

export const useArticleContentStyles = makeStyles()((theme) => ({
  contentStyle: {
    a: {
      target: "_blank",
    },
    paddingRight: theme.spacing(0),
    paddingLeft: theme.spacing(0),
    "& p": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      wordBreak: "break-word",
    },
    "& h2,h3": {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(-0.5),
    },
    // achtung: cookie button und image caption verwenden beide h6
    "& h6": {
      marginTop: "0rem",
      "@media (max-width: 1000px)": {
        marginTop: "0rem",
      },
      fontSize: "12px",
      fontStyle: "italic",

      // flexGrow: "1",
      // width: "0",
      // display: "flex",
      // https://stackoverflow.com/questions/9769587/set-div-to-have-its-siblings-width
    },
    "& img": {
      marginTop: theme.spacing(0),
      // wegen unterüberschrift weg
      // marginBottom: theme.spacing(1),
      maxWidth: "600px",
      maxHeight: "600px",
      width: "100% !important",
      height: "100% !important",
    },
    "& .wp-block-image": {
      margin: "0",
    },
  },
}));
