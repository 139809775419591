import { Container } from "@mui/material";
import { replaceHtmlWithFunctionComponent } from "../../../helpers/clientSide/replaceTagsWithFunctionComponents/replaceHtmlWithFunctionComponent";
import { mapWpContent } from "../../../helpers/general/parseWpHtmlToReact/htmlTagsToReactComponents";
import { ArticleContentProps } from "./ArticleContentInterfaces";
import { useArticleContentStyles } from "./ArticleContentStyles";

const ArticleContent = (props: ArticleContentProps): JSX.Element => {
  const { classes } = useArticleContentStyles();

  return (
    <Container className={classes.contentStyle}>
      <>{replaceHtmlWithFunctionComponent(props.content, mapWpContent)}</>
    </Container>
  );
};

export default ArticleContent;
