import { makeStyles } from "tss-react/mui";

export const useKontakformularArtikelStyles = makeStyles<{
  isContrastMode: boolean;
}>()((theme, { isContrastMode }) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "65%",
    "@media (max-width: 1100px)": {
      width: "70%",
    },
    "@media (max-width: 500px)": {
      width: "100%",
    },
  },
  short_element: {
    // width: "40%",
  },
  kontaktformStyles: {
    width: "100%",
    marginBlock: theme.spacing(3),
    display: "flex",
    justifyContent: "center",
  },
  formLableStyles: {
    marginBlock: theme.spacing(1),
  },
  kommentarButton: {
    marginTop: theme.spacing(1),

    marginBlock: "0px !important",
    "& h6": {
      fontFamily: "Lato !important",
      fontWeight: 400,
      fontSize: "17px",
    },
  },

  datenschutzlink: {
    textDecorationThickness: "3px",
    textDecorationColor: isContrastMode
      ? theme.palette.common.black
      : undefined,
    // alt
    // textDecorationColor: theme.palette.primary.main,
    "&:hover": {
      textDecorationColor: isContrastMode
        ? theme.palette.common.black
        : undefined,
    },
  },
  // "& .MuiFormControlLabel-root": {
  //   marginTop: theme.spacing(3),
  // },
}));
