import { important } from "@nvon/react-toolbox";
import { makeStyles } from "tss-react/mui";

export const useArticleFullStyles = makeStyles<{
  isContrastMode: boolean;
}>()((theme, { isContrastMode }) => ({
  authorname: {
    textAlign: "end",

    display: "inline-block",
    /*
     * display: "flex",
     * justifyContent: "end",
     */
    /*
     * targeted man so eine h6 unter dem a element?
     * h6: {
     *   textAlign: "end",
     * },
     */

    // h6 unter "a" soll ein textAlign:"end" bekommen
  },
  imageWrapper: {
    width: "100%",

    "@media (max-width: 800px)": {
      width: "100%",
    },

    "& p": {
      fontSize: "12px",
      textAlign: "center",
      fontStyle: "italic",
    },

    "& .gatsby-image-wrapper": {
      height: "auto",
      minWidth: "70% !important",
      // width: "100% !important",
      left: "50%",
      transform: important("translateX(-50%)"),
      "& img": {
        height: "auto",
      },
    },
    marginBottom: theme.spacing(0),
  },
  // wenn contrast mode: secondary color
  authorlink: {
    textDecorationThickness: "3px",
    textDecorationColor: isContrastMode
      ? theme.palette.common.black
      : undefined,
    "&:hover": {
      textDecorationColor: isContrastMode
        ? theme.palette.common.black
        : undefined,
    },
  },
  authorWrapper: {
    textAlign: "center",
    padding: theme.spacing(1),
  },
  dateContainer: {
    display: "flex",
    justifyContent: "flex-start",
  },
  date: {
    textAlign: "left",
    paddingLeft: "3px",
  },
  center: {
    textAlign: "center",
  },
  other: {
    textAlign: "center",
  },
  smallerWidth: {
    width: "70%",
    "@media (max-width: 500px)": {
      width: "80%",
    },
  },
  autorContainer: {
    diplay: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  artikelContainer: {
    "& h2,h3,h4": {
      fontWeight: "700 !important",
      marginTop: theme.spacing(3),
    },
    paddingLeft: theme.spacing(17),
    paddingRight: theme.spacing(17),
    "@media (max-width: 1200px)": {
      paddingLeft: theme.spacing(14),
      paddingRight: theme.spacing(14),
    },
    "@media (max-width: 900px)": {
      paddingLeft: theme.spacing(10),
      paddingRight: theme.spacing(10),
    },
    "@media (max-width: 700px)": {
      paddingLeft: theme.spacing(7),
      paddingRight: theme.spacing(7),
    },
    "@media (max-width: 600px)": {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    "@media (max-width: 450px)": {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
}));
