import { Chip, ChipProps, Link as MuiLink } from "@mui/material";
import { Link as GatsbyLink } from "gatsby";
import { useContext } from "react";
import { ColorMode } from "../../../layouts/theme";
import { ColorModeContext } from "../../00-globals/GlobalWrapper/GlobalWrapper";
import { ChipLinkProps } from "./ChipLinkInterfaces";
import { useChipLinkStyles } from "./ChipLinkStyles";

const getChipProps = (
  colorMode: ColorMode,
  isActive: boolean
): Pick<ChipProps, "variant" | "color"> => {
  // Zur Laufzeit der function is "isRegular" konstant, d.h. kann nicht geändert werden
  const isRegular = colorMode === ColorMode.Regular;

  if (isRegular) {
    return {
      variant: "filled",
      color: isActive ? "secondary" : "primary",
    };
  }

  // Contrast
  return {
    variant: isActive ? "filled" : "outlined",
    color: "secondary",
  };
};

export const ChipLink = ({
  href,
  isActive,
  label,
  className,
}: ChipLinkProps): JSX.Element => {
  const { classes, cx } = useChipLinkStyles();

  const { colorMode } = useContext(ColorModeContext);

  return (
    <MuiLink
      className={cx(classes.wrapper, className)}
      component={GatsbyLink}
      to={href}
    >
      <Chip label={label} {...getChipProps(colorMode, isActive)} />
    </MuiLink>
  );
};
